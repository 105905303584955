import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import * as Actions from '../state/app'
import { theme, media } from '../styles'
import ScrollMagicContext from '../components/ScrollMagic/ScrollMagicContext'

const Container = styled.div`
  width: 100vw;
`

const Content = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
  padding: 40px 0;
  ${media.tablet`
    padding: 30vw 25px;
  `}
`

const ImageItem = styled.div`
  height: 400px;
  width: 400px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  ${media.tablet`
    height: 300px;
    width: 300px;
  `}
`

const TextItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 40%;
  margin: 10px 0;

  ${media.phone`
    width: 80%;
  `}
`

const ItemHeading = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  text-align: center;

  h1 {
    color: ${props => props.color};
    font-family: ${theme.fonts.accent};
    font-size: ${theme.fontSizes.title};
    font-weight: 100;
    text-align: center;
    cursor: pointer;
    margin: 0;
  }

  h2 {
    color: ${props => props.color};
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes.h4};
    font-weight: 100;
    text-align: center;
  }

  ${media.phablet`
    h2 {
      font-size: ${theme.fontSizes.medium};
    }
  `}
`

const ItemBody = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  span, p {
    color: ${props => props.color};
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes.small};
    font-weight: 100;
    text-align: justify;
    line-height: 1.7;
  }
  
  span {
    margin: 0 5px;
  }
`

export class About extends React.Component {
  constructor(props) {
    super(props)

    this.profileImage = null
  }

  render() {
    const { colors: { light } } = theme
    const { data } = this.props
    const {
      aboutImage1,
      aboutImage2,
      aboutImage3,
      aboutImage4,
      bgImage,
    } = data
    const aboutImages = [aboutImage1, aboutImage2, aboutImage3, aboutImage4]

    return (
      <ScrollMagicContext
        location={`about`}
        vertical={true}
      >
        <Container>
          <Content
            style={{
              backgroundImage: `url(${bgImage.childImageSharp.fluid.src})`
            }}
          >
            {aboutImages.map((item, index) => <ImageItem
              key={`about_image_${index}`}
              style={{
                backgroundImage: `url(${item.childImageSharp.fluid.src})`,
              }} />
            )}
            <TextItem>
              <ItemHeading shade={light}>
                <a
                  href="mailto:eee.tcha@gmail.com"
                  target="_blank"
                >
                  <h1>get in touch.</h1>
                </a>
              </ItemHeading>
            </TextItem>
            <TextItem>
              <ItemBody>
                <a
                  href="https://www.instagram.com/estellalicious"
                  target="_blank"
                >
                  <span>
                    @estellalicious
                  </span>
                </a>
                <a
                  href="https://www.instagram.com/eee.official"
                  target="_blank"
                >
                  <span>
                    @eee.official
                  </span>
                </a>
              </ItemBody>
            </TextItem>
          </Content>
        </Container>
      </ScrollMagicContext>
    )
  }
}

export const query = graphql`
  query {
    profileImage: file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImage1: file(relativePath: { eq: "about-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImage2: file(relativePath: { eq: "about-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImage3: file(relativePath: { eq: "about-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImage4: file(relativePath: { eq: "about-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "about-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function mapStateToProps({ displayNav }) {
  return { displayNav }
}

function mapDispatchToProps(dispatch) {
  return {
    toggle: () => { dispatch(Actions.toggleNav()) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About)
